<template>
    <div class="detail-pic">
        <!-- 中图片 -->
        <div class="detail-pic-md">
            <el-image
                src=""
                fit="scale-down"
                lazy
                class="detail-pic-md-content">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- 小图片列表 -->
        <ul class="detail-pic-sm">
            <li v-for="(item, index) in 5"
                :key="index">
                <a href="javascript:;">
                    <el-image
                        src=""
                        fit="scale-down"
                        class="detail-pic-sm-content">
                        <!-- 加载中样式 -->
                        <template v-slot:placeholder>
                            <div class="image-slot">
                                <i class="el-icon-loading"></i>
                            </div>
                        </template>
                        <!-- 加载失败样式 -->
                        <template v-slot:error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.detail-pic{
    width: 100%;
    padding: 20px;
}
/* 中图片 */
.detail-pic-md{
    position: relative;
    width: 100%;
}
.detail-pic-md::before,
.detail-pic-sm > li::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.detail-pic-md-content,
.detail-pic-sm-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* 小图片列表 */
.detail-pic-sm{
    display: flex;
    margin-top: 10px;
}
.detail-pic-sm > li{
    position: relative;
    width: 20%;
}
.detail-pic-sm-content{
    padding: 4px;
}
</style>